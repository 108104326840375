import { FC, useState, useContext, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

import RecoveryForm from "./RecoveryForm";
import FooterForm from "../footer_component/FooterForm";

import style from "../../css/auth_css/authForm.module.css";

import logo from "../../img/logo_img/logo.svg";
import login_background from "../../img/auth_img/login_background.png";
import register_background from "../../img/auth_img/register_background.jpg";
import recovery_background from "../../img/auth_img/recovery.svg";;

const LoginForm: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [isLoginFormVisible, setLoginFormVisible] = useState<boolean>(true);
  const [isRecoveryFormVisible, setRecoveryFormVisible] =
    useState<boolean>(false);
  const { authStore, globalStore, profileStore } = useContext(Context);

  useEffect(() => {
    if (globalStore.isRegisterForm) {
      setLoginFormVisible(false);
    }
  }, [globalStore.isRegisterForm]);

  const toggleForms = () => {
    authStore.setErrorMessage("");
    setUser("");
    setEmail("");
    setPassword("");
    setRepeatPassword("");
    setLoginFormVisible(!isLoginFormVisible);
  };

  return (
    <div className={style.container}>
      <img
        className={style.background}
        src={
          isRecoveryFormVisible || globalStore.isRecoveryFrom
            ? recovery_background
            : isLoginFormVisible
            ? login_background
            : register_background
        }
        alt="background"
      />
      <div className={style.content}>
        {globalStore.isRecoveryFrom ? (
          <RecoveryForm />
        ) : (
          <div className={style.login}>
            <div className={`${style.logo_name} ${style.flex}`}>
              <div>
                <img
                  className={style.logo}
                  onClick={() => {
                    globalStore.setAuthForm(false);
                    globalStore.setAuthFooterForm(false);
                    authStore.setErrorMessage("");
                  }}
                  src={logo}
                  alt="logo"
                />
              </div>
              <p className={style.text}>
                {isRecoveryFormVisible
                  ? "Восстановление пароля"
                  : isLoginFormVisible
                  ? "Вход"
                  : "Регистрация"}
              </p>
            </div>

            <div className={style.login_container}>
              <form className={`${style.form} ${style.form_login}`}>
                {!isLoginFormVisible && (
                  <div className={style.form_label}>
                    <label>Логин</label>
                    <input
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      type="text"
                      placeholder="Введите логин"
                      maxLength={50}
                    />
                  </div>
                )}
                {!authStore.isSuccessSend && (
                  <div className={style.form_label}>
                    <label>Почта</label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      placeholder="Почта"
                      maxLength={50}
                    />
                  </div>
                )}

                {!isRecoveryFormVisible && (
                  <div className={style.form_label}>
                    <label>Пароль</label>
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      type="password"
                      maxLength={50}
                      placeholder="Пароль"
                    />
                  </div>
                )}

                {!isLoginFormVisible && (
                  <div className={style.form_label}>
                    <label>Повторите пароль</label>
                    <input
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      value={repeatPassword}
                      type="password"
                      placeholder="Повторите пароль"
                      maxLength={50}
                    />
                  </div>
                )}

                {!authStore.isSuccessSend &&
                  (isRecoveryFormVisible ? (
                    <button
                      className={style.recovery_btn}
                      type="button"
                      onClick={async () => {
                        await authStore.recovery(email);
                      }}
                    >
                      Отправить письмо подтверждение
                    </button>
                  ) : isLoginFormVisible ? (
                    <button
                      className={style.login_btn}
                      type="button"
                      onClick={async () => {
                        await authStore.login(email, password);
                        if (authStore.isAuth) {
                          await profileStore.getImg();
                          globalStore.setProfileForm(true);
                          globalStore.setAuthFooterForm(false);
                        }
                      }}
                    >
                      Войти
                    </button>
                  ) : (
                    <button
                      className={style.reg_btn}
                      type="button"
                      onClick={async () => {
                        await authStore.registration(
                          user,
                          email,
                          password,
                          repeatPassword
                        );
                        if (authStore.isAuth) {
                          globalStore.setProfileForm(true);
                          globalStore.setAuthFooterForm(false);
                        }
                      }}
                    >
                      Зарегистрироваться
                    </button>
                  ))}

                {!isRecoveryFormVisible && (
                  <button
                    className={style.register_btn}
                    type="button"
                    onClick={toggleForms}
                  >
                    {isLoginFormVisible ? "Регистарция" : "Авторизация"}
                  </button>
                )}

                {authStore.isSuccessSend && (
                  <h2 className={style.success_text}>Сообщение отправлено</h2>
                )}

                {isLoginFormVisible && (
                  <button
                    className={style.register_btn}
                    type="button"
                    onClick={() => {
                      authStore.setSuccessSend(false);
                      authStore.setErrorMessage("");
                      setRecoveryFormVisible(!isRecoveryFormVisible);
                    }}
                  >
                    {isRecoveryFormVisible
                      ? "Авторизоваться"
                      : "Забыл пароль от аккаунта"}
                  </button>
                )}

                {authStore.errorMessage && (
                  <div
                    className={`${style.error} ${
                      authStore.isShake ? style.shake_animation : ""
                    }`}
                  >
                    {authStore.errorMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
        <FooterForm />
      </div>
    </div>
  );
};

export default observer(LoginForm);
