import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import AuthService from "../service/AuthService";
import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { API_URL } from "../http/index";

export default class AuthStore {
    user = {} as IUser
    isAuth = false
    isShake = false
    isSuccessSend = false
    errorMessage: string = ''
    recoveryLink: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool: boolean){
        this.isAuth = bool
    }

    setUser(user: IUser){
        this.user = user
    }
        
    setShake(bool: boolean){
        this.isShake = bool
    }

    setSuccessSend(bool: boolean){
        this.isSuccessSend = bool
    }

    setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage
    }

    setRecoveryLink(recoveryLink: string) {
        this.recoveryLink = recoveryLink
    }

    async login(email: string, password: string) {
        try{
            const response = await AuthService.login(email, password)
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            this.setErrorMessage("")
        } catch (e:any) { 
            this.setShake(false)
            this.setErrorMessage("Неверная почта или пароль");
            if (e?.response?.data?.message.includes('Invalid email')) this.setErrorMessage("Неверный формат электронной почты");
            if (e?.response?.data?.message.includes('Invalid password')) this.setErrorMessage("Неверный формат пароля");
            if (e?.response?.data?.message.includes('required')) this.setErrorMessage("Не все поля заполнены");
        
             setTimeout(() => {
                this.setShake(true)
            }, 10);
            //console.log(e.response?.data?.message)
        }
    }

    async registration(username: string, email: string, password: string, repeatPassword: string) {
        try{
            if (repeatPassword === password) {
                const response = await AuthService.registration(username, email, password)
                localStorage.setItem('token', response.data.accessToken)
                this.setAuth(true)
                this.setUser(response.data.user)
                this.setErrorMessage("")
            } else {
                throw new Error('Пароли не совпадают')
            }
        } catch (e:any) {
            this.setShake(false)
            if (e?.message) this.setErrorMessage(e?.message)
            if (e?.response?.data?.message.includes('email')) this.setErrorMessage("Пользователь с такой почтой уже существует");
            if (e?.response?.data?.message.includes('login')) this.setErrorMessage("Пользователь с таким логином уже существует");
            if (e?.response?.data?.message.includes('Invalid login')) this.setErrorMessage("Неверный формат логина");
            if (e?.response?.data?.message.includes('Invalid email')) this.setErrorMessage("Неверный формат электронной почты");
            if (e?.response?.data?.message.includes('Invalid password')) this.setErrorMessage("Неверный формат пароля");
            if (e?.response?.data?.message.includes('required')) this.setErrorMessage("Не все поля заполнены");
            if (e?.response?.data?.message.includes('The message could not be sent to the specified email address')) this.setErrorMessage("На указанный адрес электронной почты не удалось отправить сообщение");
             setTimeout(() => {
                this.setShake(true)
            }, 10);
        }
    }

    async recovery(email: string) {
        try{
            await AuthService.recovery(email)
            this.setSuccessSend(true)
            this.setErrorMessage("")
        } catch(e: any) {
            this.setShake(false)
            this.setErrorMessage("Непредвиденная ошибка")
            if (e?.response?.data?.message.includes('User with email')) this.setErrorMessage("Пользователь с таким адресом электронной почты не существует");
            if (e?.response?.data?.message.includes('Email are required')) this.setErrorMessage("Поле не заполнено");
            if (e?.response?.data?.message.includes('Invalid email format')) this.setErrorMessage("Неверный формат электронной почты");

            setTimeout(() => {
                this.setShake(true)
            }, 10);
        }
    }

    async changePassword(password: string, repeatPassword: string) {
        try{
            if (repeatPassword === password) {
                await AuthService.changePassword(password, this.recoveryLink)
                this.setSuccessSend(true)
                this.setErrorMessage("")
            } else {
                throw new Error('Пароли не совпадают')
            }
        } catch (e:any) {
            this.setShake(false)
            if (e?.message) this.setErrorMessage(e?.message)
            if (e?.response?.data?.message.includes('Password or link are required')) this.setErrorMessage("Не все поля заполнены");
            if (e?.response?.data?.message.includes('Invalid password')) this.setErrorMessage("Неверный формат пароля");
            
             setTimeout(() => {
                this.setShake(true)
            }, 10);
        }
    }

    async logout() {
        try{
            await AuthService.logout()
            localStorage.removeItem('token')
            this.setAuth(false)
            this.setUser({} as IUser)
        } catch (e:any) { 
            console.log(e.response?.data?.message)
        }
    }

    async checkAuth(){
        try{
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e:any) { 
            console.log(e.response?.data?.message)
        }
    }
}