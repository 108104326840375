import { FC, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Context } from "../../index";

import style from "../../css/footer_css/FooterForm.module.css";

import telegramImg from "../../img/main_img/telegram.svg";
import vkImg from "../../img/main_img/vk.svg";
import discordImg from "../../img/main_img/discord.svg";

const FooterForm: FC = () => {
  const { globalStore } = useContext(Context);

  return (
    <footer className={`${globalStore.isAuthFooterForm && style.footer_auth} ${style.footer}`}>
      <hr />
      {globalStore.isAuthFooterForm ? (
        <div>
          <p>Пользовательское соглашение</p>
          <p>Политика конфиденциальности</p>
          <p>© 2024 Rutoor</p>
        </div>
      ) : (
        <div className={style.footer_wrapper}>
          <div className={style.footer_top}>
            <div className={style.footer_top_left}>
              <p className={style.about} onClick={() => {globalStore.setFalseALL(); globalStore.setAboutForm(true)}}>О нас</p>
              <p>Техническая поддержка</p>
            </div>
            <div className={style.footer_top_right}>
              <a
                href="#"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <p>Пользовательское соглашение</p>
              </a>
              <a
                href="#"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <p>Политика конфиденциальности</p>
              </a>
            </div>
          </div>
          <div className={style.footer_bottom}>
            <div className={style.footer_bottom_left}>
              <div className={style.footer_bottom_left_images}>
                <p style={{ marginTop: "5px" }}>Социальные сети</p>
                <a
                  className={style.telegramImg}
                  href="https://t.me/+DUjRFYJXcVY1Y2Ni"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img src={telegramImg} alt="tg" />
                </a>
                <a
                  className={style.vkImg}
                  href="https://vk.com/rutoor"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img src={vkImg} alt="vk" />
                </a>
                <a
                  className={style.discordImg}
                  href="https://discord.gg/teUy2AXgG8"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img src={discordImg} alt="discord" />
                </a>
              </div>
            </div>
            <div className={style.footer_bottom_right}>
              <p>© 2024 Rutoor</p>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default observer(FooterForm);
