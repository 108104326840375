import { FC, useContext, useEffect } from "react";
import { Context } from "./index";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";

import LoginForm from "./components/auth_components/AuthForm";
import LoadingForm from "./components/auth_components/LoadingForm";
import MainForm from "./components/main_component/MainForm";
import NotFondForm from "./components/not_found_components/NotFondForm";
import RatingForm from "./components/rating_components/RatingForm";
import ProfileForm from "./components/profile_component/ProfileForm";
import WebForm from "./components/task_components/WebForm";
import ReverseForm from "./components/task_components/ReverseForm";
import CategoriesForm from "./components/task_components/CategoriesForm";
import CryptoForm from "./components/task_components/CryptoForm";
import MagicRandForm from "./components/task_components/revers_task_components/MagicRandForm";
import Layout from "./components/Layout";

const App: FC = () => {
  const { authStore, globalStore, profileStore } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (localStorage.getItem("token")) {
        globalStore.setAuthLoading(true)
        await authStore.checkAuth();
      }

      if (authStore.isAuth) {
        await profileStore.getImg();
      }

      globalStore.setAuthLoading(false)
    };

    checkAuthentication();

  }, [authStore, profileStore, globalStore]);

  useEffect(() => {
    const getProfile = async () => {
      await profileStore.getProfile()
    }

    if (location.pathname === "/profile") {
      globalStore.setFalseALL();
      globalStore.setProfileForm(true);
      getProfile()
    }

    if (location.pathname.startsWith("/recovery/")) {
      authStore.setRecoveryLink(location.pathname.split("/")[2])
      globalStore.setFalseALL()
      globalStore.setRecoveryForm(true)
      globalStore.setAuthForm(true)
    }

    if (location.pathname === "/notfound"){
      globalStore.setFalseALL();
      globalStore.setNotFoundForm(true)
    }

    navigate("/", { replace: true });
  }, [location.pathname, authStore, globalStore, navigate]);

  if (globalStore.isAuthLoading) {
    return <LoadingForm />;
  }

  if (authStore.isAuth && globalStore.isProfileForm) {
    return <ProfileForm />;
  }

  if (globalStore.isAuthForm) {
    return <LoginForm />;
  }

  if (globalStore.isRatingForm) {
    return <RatingForm />;
  }

  if (globalStore.isNotFoundForm) {
    return <NotFondForm/>
  }

  if (globalStore.isWebForm) {
    return <WebForm/>
  }

  if (globalStore.isReverseForm) {
    return <ReverseForm/>
  }

  if (globalStore.isCryptForm) {
    return <CryptoForm/>
  }

  if (globalStore.isCategoriesForm) {
    return <CategoriesForm />;
  }

  if (globalStore.isMagicRandForm) {
    return (
      <Layout>
        <MagicRandForm />
      </Layout>
    );
  }

  return <MainForm />;
};

export default observer(App);
