import $api from '../http'
import {AxiosResponse} from 'axios'
import { AuthResponse } from '../models/response/AuthResponse'

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/login', {email, password})
    }

    static async registration(username: string, email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/register', {username, email, password})
    }

    static async recovery(email: string): Promise<void> {
        return $api.post('/send-recovery', {email})
    }

    static async changePassword(password: string, link: string): Promise<void> {
        return $api.post('/change-password', {password, link})
    }

    static async logout(): Promise<void> {
        return $api.post('/logout')
    }
}
