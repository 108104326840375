import { createContext, ReactNode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

import AuthStore from "./store/auth_store";
import GlobalStore from "./store/global_store";
import ProfileStore from "./store/profile_store"

interface State {
  authStore: AuthStore;
  globalStore: GlobalStore;
  profileStore: ProfileStore;
}

const authStore = new AuthStore();
const globalStore = new GlobalStore();
const profileStore = new ProfileStore();

export const Context = createContext<State>({ authStore, globalStore, profileStore });

const Providers = ({ children }: { children: ReactNode }) => {
  const contextValue = { authStore, globalStore, profileStore };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Router>
    <Providers>
      <App />
    </Providers>
  </Router>
);