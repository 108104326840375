import { FC } from "react";
import { observer } from "mobx-react-lite";

import style from "../../css/profile_css/isActiveForm.module.css";

import mask from "../../img/profile_img/maskGroup.svg"

const IsActiveForm: FC = () => {
    return (
      <div className={style.index}>
        <div className={style.container}>
          <p className={style.text1}>Опаньки...!</p>
          <p className={style.text2}>
            Кажется, ваша учётная запись не подтверждена...
          </p>
          <p className={style.text3}>
            Чтобы исправить это, подтвердите учётную запись по ссылке в письме,
            отправленном на вашу почту.
          </p>
        </div>
        <div className={style.img_wrapper}>
          <img className={style.img} src={mask} alt="img"/>
        </div>
      </div>
    );

}

export default observer(IsActiveForm);