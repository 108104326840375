import { FC, useEffect, useState} from "react";
import { observer } from "mobx-react-lite";
import { IUser } from "../../models/IUser";
import UserService from "../../service/UserService";

import HeaderForm from "../header_component/HeaderForm";
import FooterForm from "../footer_component/FooterForm";

import style from "../../css/rating_css/RatingForm.module.css";

const RatingForm: FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);

  const getUsers = async () => {
    try {
      const response = await UserService.fetchUsers();
      const usersData: IUser[] = response.data;
      setUsers(usersData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect( () => {
    getUsers()
  }, [])

  return (
    <div>
      <HeaderForm />
      <div className={style.index}>
        <h1>Рейтинг</h1>
          <div className={style.userList}>
            {users.map((user) => (
              <div key={user.id} className={style.userItem}>
                <p>{user.username}</p>
              </div>
            ))}
          </div>
      </div>
      <FooterForm />
    </div>
  );
};

export default observer(RatingForm);